import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AspectRatio from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css';
import { MdTrendingFlat } from 'react-icons/md';
const Container = styled.div`
    display: block;
    position: relative;
    width: calc(33.33% - 22px);
    margin-right: 32px;
    &:nth-child(3n) {
        margin-right: 0;
    }
    margin-bottom: 32px;
    img {
        object-fit: cover;
        border-radius: 4px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 24px;
        margin-right: 0;
    }
`;

const Content = styled.div`
    position: absolute;
    width: calc(100% - 48px);
    height: 100%;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    @media screen and (max-width: 767px) {
        width: calc(100% - 48px);
    }
`;
const Title = styled.div`
    margin-top: auto;
    font-family: 'Renogare-Regular';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: white;
    text-align: left;
    @media screen and (max-width: 767px) {
    }
`;

const MoreButton = styled.a`
    font-family: 'Renogare-Regular';
    margin-top: 8px;
    color: white;
    font-size: 12px;
    line-height: 24px;
    margin-right: 4px;
    letter-spacing: 0.5px;
    text-decoration: none;
    display: flex;
    margin-bottom: 24px;
    &:focus {
        opacity: 0.7;
    }
`;

export default function Case({ featured_image, title, url_slug }) {
    return (
        <Container>
            <Content>
                <Title>{title}</Title>
                <MoreButton href={`/case-studies/${url_slug}`}>
                    <React.Fragment>
                        <span>READ STUDY</span>
                        <MdTrendingFlat size={24} color={'white'} />
                    </React.Fragment>
                </MoreButton>
            </Content>
            <AspectRatio>
                <LazyLoadImage
                    alt={title}
                    effect="blur"
                    height={'100%'}
                    src={featured_image} // use normal <img> attributes as props
                    width={'100%'}
                />
            </AspectRatio>
        </Container>
    );
}
